<script setup lang="ts">
import { computed, useAttrs, ref } from "vue"
import { ensureOptionsArray, type OptionsObjectOrArray } from "../utils/transform-options-object"
import { isEmpty, isInteger } from "lodash"
import { ElSelect, ElOption, ElTag, ElOptionGroup } from "element-plus"
import { useNiceUi } from "@nice-ui" // I18n is provided by NiceUi (see crm.ts)

defineOptions({
  inheritAttrs: false,
})

const { t } = useNiceUi()

interface NiceSelectBaseProps {
  multiple?: boolean
  clearable?: boolean
  disabled?: boolean
  allowCreate?: boolean
  collapseTags?: boolean
  placeholder?: string
  itemDisabledFn?: (item) => boolean
  size?: "default" | "small" | "large"
  labelKey?: string
  labelFn?: (key: string | number) => string
  idKey?: string
  childrenKey?: string
  options?: OptionsObjectOrArray
  grouped?: boolean
  highlightOnEmpty?: boolean
}

interface NiceSelectPropsMultiple<T> extends NiceSelectBaseProps {
  modelValue?: T[] | null
  multiple?: true
}

interface NiceSelectPropsSingle<T> extends NiceSelectBaseProps {
  modelValue?: T | null
  multiple?: false
}

const props = withDefaults(
  defineProps<NiceSelectPropsMultiple<string | number> | NiceSelectPropsSingle<string | number>>(),
  {
    multiple: false,
    clearable: true,
    disabled: false,
    allowCreate: false,
    collapseTags: false,
    placeholder: undefined,
    itemDisabledFn: undefined,
    size: "default",
    labelKey: "name",
    idKey: "id",
    childrenKey: "children",
    options: undefined,
    grouped: false,
    labelFn: undefined,
    highlightOnEmpty: false,
  }
)

const customClear = ref(null)
const filterTerm = ref("")
const model = defineModel<string | string[] | number | number[] | null>({ default: null })

const emits = defineEmits(["change", "blur", "update:modelValue"])
const attrs = useAttrs()

const select = ref<typeof ElSelect | null>(null)
const containerRef = ref<HTMLElement | null>(null)

const placeholderNormalized = computed(() => props.placeholder ?? t("niceSelect.placeholder"))

const allOptions = computed(() => {
  if (props.options) {
    return ensureOptionsArray(props.options, props.labelKey)
  }

  // if no options are provided, try to generate the options from the model value (for create tags)
  return Array.isArray(model.value) ? model.value.map(val => ({ [props.labelKey]: val, [props.idKey]: val })) : []
})

const filteredOptions = computed(() => {
  const filterFn = item => item[props.labelKey]?.toLowerCase()?.includes(filterTerm.value)

  return allOptions.value.filter(filterFn).slice(0, 500)
})

const clear = () => {
  emits("update:modelValue", props.multiple ? [] : null)
  emits("change", props.multiple ? [] : null)
}

const hasValues = computed(() => isInteger(model.value) || !isEmpty(model.value))

const focus = () => {
  select.value?.focus()
}

const blur = () => {
  select.value?.blur()
}

const handleBlur = () => {
  emits("blur")
}

const updateValue = value => {
  emits("update:modelValue", value)
}

const handleChange = value => {
  emits("change", value)
  if (!props.multiple) {
    blur()
  }
}

const onRemoveValue = key => {
  if (Array.isArray(model.value)) {
    // Key is a number, values a string array 🙃
    const newValue = model.value.filter(v => String(v) !== String(key))

    emits("change", newValue)
    emits("update:modelValue", newValue)
  }
}

defineExpose({ select, focus, blur })

const labelFor = (key: string | number) => {
  return props.labelFn
    ? props.labelFn(key)
    : allOptions.value.find(option => option[props.idKey] === key)?.[props.labelKey]
}

const filterMethod = query => {
  filterTerm.value = query?.toLowerCase()
}

const containerRefWidth = computed(() => {
  return containerRef.value ? `${containerRef.value.clientWidth}px` : "100%"
})
</script>

<template>
  <div
    ref="containerRef"
    :class="['nice-select-el relative inline-flex w-100', { 'has-values': hasValues, clearable: clearable }]"
  >
    <span v-if="clearable && hasValues && !disabled" ref="customClear" class="nice-select-el__clear absolute z-10">
      <i @click.stop="clear" class="fal fa-times-circle clear-button"></i>
    </span>
    <el-select
      v-bind="attrs"
      default-first-option
      filterable
      :disabled="disabled"
      :multiple="multiple"
      :clearable="clearable"
      :allow-create="allowCreate"
      :model-value="model"
      @update:model-value="updateValue"
      :placeholder="placeholderNormalized"
      :collapse-tags="collapseTags"
      :size="size"
      :reserve-keyword="false"
      :data-highlight-on-empty="(highlightOnEmpty && !hasValues) || undefined"
      :filter-method="
        !!(attrs['remote-method'] || attrs['remoteMethod']) || allOptions.length <= 500 ? null : filterMethod
      "
      ref="select"
      @change="handleChange"
      @blur="handleBlur"
    >
      <template #tag>
        <slot name="tags" v-bind="{ $onRemoveValue: onRemoveValue, values: model }">
          <span v-for="value in model" :key="value">
            <el-tag>
              <span
                class="text-black"
                :style="{
                  // this prevents values with long labels to cause jumping of the UI when displaying the delete button
                  display: 'inline-block',
                  'max-width': `calc(${containerRefWidth} - 72px)`,
                  overflow: 'hidden',
                  'text-overflow': 'ellipsis',
                  'white-space': 'nowrap',
                }"
              >
                {{ labelFor(value) }}
              </span>
              <i
                v-if="!disabled"
                class="fal fa-times clear-tag text-slate-500 hover:text-slate-800 ml-2 cursor-pointer"
                @click.prevent="onRemoveValue(value)"
              />
            </el-tag>
          </span>
        </slot>
      </template>
      <template #label="{ label, value }">
        <slot name="label" v-bind="{ label, value }">
          <span v-if="labelFn">{{ labelFor(value) }}</span>
          <span v-else>{{ label }}</span>
        </slot>
      </template>

      <slot>
        <template v-if="grouped">
          <!-- max width matching container width is needed to prevent the popper element to jump
          when the option label is too long -->
          <el-option-group v-for="group in filteredOptions" :key="group[idKey]" :label="group[labelKey]">
            <el-option
              v-for="item in group[childrenKey]"
              :label="item[labelKey]"
              :key="item.id"
              :value="item.id"
              :disabled="item.disabled"
            >
              <slot v-bind="{ item, group }" name="option">{{ item[labelKey] }}</slot>
            </el-option>
          </el-option-group>
        </template>
        <template v-else>
          <el-option
            v-for="item in filteredOptions"
            :label="item[labelKey]"
            :key="item[idKey]"
            :value="item[idKey]"
            :disabled="(itemDisabledFn && itemDisabledFn(item)) || item.disabled"
          >
            <slot name="option" v-bind="{ item }">{{ item[labelKey] }}</slot>
          </el-option>
        </template>
      </slot>
    </el-select>
  </div>
</template>

<style>
.nice-select-el .el-tag {
  transition: none !important;
}

/* generally disable el-select transitions when opening the dropdown
 this is to ensure the dropdown is immediately visible because animation causes
 absolute positioning issues when nested inside other popovers */
.el-select__popper {
  transition: none !important;
}

.nice-select-el__clear:has(+ .el-select.mr-2) {
  right: 18px;
}

.nice-select-el.size-small .nice-select-el__clear {
  font-size: var(--ps-font-size-sm);
  margin-top: -6px;
}

.nice-select-el__clear {
  position: absolute;
  color: var(--ps-color-danger);
  transition: none;
  cursor: pointer;
  display: none;
  right: 10px;
  margin-top: -7px;
  top: 50%;
}

.nice-select-el.has-values:hover .nice-select-el__clear {
  display: inline-flex;
}

.nice-select-el.has-values.clearable:hover .el-select__suffix {
  opacity: 0;
}

.el-select__wrapper {
  font-size: var(--ps-font-size-md);
}

.el-select-dropdown__item {
  height: fit-content;
  line-height: auto;
}

.nice-select-el {
  [data-highlight-on-empty="true"].el-select {
    --el-border-color-hover: var(--ps-color-danger);
    --el-border-color: var(--ps-color-danger);
    .el-select__caret {
      color: var(--ps-color-danger);
    }
  }
}
</style>
